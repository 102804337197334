import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function Trading(props) {
  return (
    <Layout {...props}>
      <Seo 
        title="Trading" 
        description="Delivers different architectural and engineering related items to every client who are in need. With the assurance that such items are the best in quality." 
      />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="Trading"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            Delivers different architectural and engineering related items to every client who are in need. With the assurance
            that such items are the best in quality.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
